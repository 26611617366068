import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import MainContent from "../components/DefaultPage/MainContent"

const DefaultPage = props => {
  const location = props.location

  return (
    <Layout location={location}>
      <SEO />
      <MainContent data={props.data.mainContent} />
    </Layout>
  )
}

export const aboutQuery = graphql`
  query defaultPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    mainContent: wordpressPage(wordpress_id: { eq: $id }) {
      title
      acf {
        _wfc_main_content_wysiwyg
      }
    }
  }
`

export default DefaultPage
